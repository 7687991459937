import { PUBLIC_ROUTES } from "./publicroutes";
import { PRIVATE_ROUTES } from "./privateroutes";

const MENU_NAV = Object.freeze({
  index: "Courant alternatif",
  creerCompte: "S'inscrire gratuitement",
  connexion: "Connexion",
  compte: "Mon compte",
  avis: "Livre d'Or",
  manifesto: "Qui sommes-nous ?",
  feed: "Actualités du Tramway",
  aide: "Aide & Sécurité",
  contact: "Contact",
  mentionsLegales: "Mentions légales",
  accessibilite: "Aide & Accessibilité",
  sitemap: "Plan du site",
  faq: "Questions fréquentes",
  glossaire: "Le glossaire du Tramway",
  CGV_CGU: "CGV & CGU",
});

const NAV = Object.freeze({
  deconnexion: `${PRIVATE_ROUTES.private}/${PRIVATE_ROUTES.deconnexion}`,
  compte: `${PRIVATE_ROUTES.private}/${PRIVATE_ROUTES.compte}`,
  connexion: PUBLIC_ROUTES.connexion,
  creerCompte: PUBLIC_ROUTES.creerCompte,
  avis: PUBLIC_ROUTES.avis,
  manifesto: PUBLIC_ROUTES.manifesto,
  feed: PUBLIC_ROUTES.feed,
  contact: PUBLIC_ROUTES.contact,
  aide: PUBLIC_ROUTES.aide,
  mentionsLegales: PUBLIC_ROUTES.mentionsLegales,
  accessibilite: PUBLIC_ROUTES.accessibilite,
});

export { NAV, MENU_NAV };
