import { lazy, Suspense } from "react";

import {
  Well,
  Button,
  DialogTrigger,
  Tooltip,
  TooltipTrigger,
  Flex,
  Heading,
  Content,
  ContextualHelp,
  View,
  Text,
  Footer,
} from "@adobe/react-spectrum";

import TramFlashIcon from "./TramFlashIcon";
import Follow from "@spectrum-icons/workflow/Heart";

import Loading from "./Loading";
const LazyTipsDialog = lazy(() => import("./TipsDialog"));

function PublicSubFooter() {
  return (
    <View marginY="size-100" width={{ base: "100%", S: "100%", M: "100%", L: "25%" }}>
      <Flex direction="column" wrap marginY="size-100" alignItems="start" justifyContent="center" gap="size-250">
        <Flex alignItems="center" justifyContent="center">
          <TramFlashIcon aria-label="Flash, coup de foudre" size="L" color="DarkGoldenRod" />
          <Heading margin="size-10" level={3}>
            Tramway
          </Heading>
          <ContextualHelp variant="info">
            <Heading>
              Tramway • courant alternatif{" "}
              <TramFlashIcon aria-label="Flash, coup de foudre" size="S" color="DarkGoldenRod" /> <span>&#x00AE;</span>
            </Heading>
            <Content>
              <Well marginY="size-100">
                {`Tramway est une marque Française déposée à l’Institut National de la Propriété Intellectuelle (INPI) sous le N° 5027214 • 2023-${new Date().getFullYear()} tous droits réservés.`}
              </Well>
              <Well marginY="size-100">
                {" "}
                Tramway est une petite plateforme indépendante de rencontre créée par Bluecornflakes : entreprise
                non-diffusible, Article A123-96 du code de Commerce.
              </Well>
              <Well marginY="size-100">
                Les membres du Tramway doivent être légalement majeur et en mesure de prendre des décisions en toute
                responsabilité, conformément à la loi. En résumé : utiliser uniquement le Tramway de manière légale,
                authentique et positive.
              </Well>
            </Content>

            <Footer>
              {" "}
              <Text>
                <span lang="en">
                  Bluecornflakes • digital native <span title="emoji : pieds nus">👣</span>
                </span>
              </Text>
            </Footer>
          </ContextualHelp>
        </Flex>

        <Text>
          La petite plateforme de rencontre accessible pour les amoureux du bio et de la nature. Laissez le courant
          alternatif vous Éveiller et trouvez un partenaire, l'amour
          {` • 2023-${new Date().getFullYear()} tous droits réservés.`}
        </Text>

        <DialogTrigger type="tray">
          <TooltipTrigger delay={0}>
            <Button style="fill" variant="secondary" aria-label="Faire un Don">
              <Follow color="negative" /> <Text>By Bluecornflakes</Text>
            </Button>
            <Tooltip>Faire un don sur notre blog KOFI</Tooltip>
          </TooltipTrigger>

          <Suspense fallback={<Loading />}>
            <LazyTipsDialog />
          </Suspense>
        </DialogTrigger>
      </Flex>
    </View>
  );
}

export default PublicSubFooter;
