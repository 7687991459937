import { useState, useCallback } from "react";

const usePseudoValidation = () => {
  const [pseudo, setPseudo] = useState("");
  const [isPseudoValid, setIsPseudoValid] = useState(null);

  const handlePseudoChange = useCallback((value) => {
    const normalizedPseudo = value && value.trim().toLowerCase();
    setPseudo(normalizedPseudo);
    setIsPseudoValid(/^[a-zA-Z]{3,12}$/.test(normalizedPseudo));
  }, []);

  return { pseudo, isPseudoValid, handlePseudoChange };
};

export default usePseudoValidation;
