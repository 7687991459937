import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useCounter } from "@uidotdev/usehooks";

const useTemporarilyLoading = (statusError, maxCounter) => {
  const tooManyRequest = statusError === 429;

  const [minValue] = useState(0);
  const [maxValue, setMaxValue] = useState(null);

  const [count, { increment }] = useCounter(minValue, {
    min: minValue,
    max: maxValue,
  });

  useEffect(() => {
    setMaxValue(maxCounter);
    if (tooManyRequest) {
      const inProgress = setInterval(() => {
        increment();
      }, 250);

      return () => {
        clearInterval(inProgress);
        setMaxValue(null);
      };
    }
  }, [tooManyRequest, increment, maxCounter]);

  return { count, tooManyRequest, maxValue };
};

useTemporarilyLoading.propTypes = {
  statusError: PropTypes.number,
  maxCounter: PropTypes.number,
};

export default useTemporarilyLoading;
