import { useState } from "react";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";
import { PUBLIC_ROUTES } from "../constants/publicroutes";

const useSupabaseSignInWithOtp = () => {
  const navigate = useNavigate();

  const [signInLoading, setSignInLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [signInMessage, setSignInMessage] = useState("");
  const [signInMessageVariant, setSignInMessageVariant] = useState("");
  const [signInShowMessageOutsideForm, setSignInShowMessageOutsideForm] = useState(false);

  const signInWithOtp = async (email, pseudo, areCheckboxesValid, is_active, captchaToken) => {
    setSignInLoading(true);
    const { error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        captchaToken,
        data: {
          name: pseudo ?? undefined,
          terms: areCheckboxesValid ?? undefined,
          is_active: is_active ?? undefined,
        },
      },
    });

    if (error) {
      console.log(error.message);
      if (error.status) {
        setStatus(error.status);
        switch (error.status) {
          case 400:
            setSignInMessageVariant("notice");
            setSignInLoading(false);
            setSignInShowMessageOutsideForm(false);
            setSignInMessage("La requête est incorrecte. Veuillez vérifier les données fournies.");
            break;
          case 401:
            setSignInMessageVariant("notice");
            setSignInLoading(false);
            setSignInShowMessageOutsideForm(false);
            setSignInMessage("Vous n'êtes pas autorisé à accéder à cette ressource. Veuillez vous connecter.");
            break;
          case 403:
            setSignInMessageVariant("notice");
            setSignInLoading(false);
            setSignInShowMessageOutsideForm(false);
            setSignInMessage("Accès interdit. Vous n'avez pas les autorisations nécessaires.");
            navigate("../" + PUBLIC_ROUTES.error403);
            break;
          case 404:
            setSignInMessageVariant("notice");
            setSignInLoading(false);
            setSignInShowMessageOutsideForm(false);
            setSignInMessage("La ressource demandée n'a pas été trouvée.");
            break;
          case 429:
            setSignInLoading(false);
            setSignInMessageVariant("notice");
            setSignInShowMessageOutsideForm(false);
            setSignInMessage("Trops de requêtes...");
            break;
          case 500:
            setSignInMessageVariant("notice");
            setSignInLoading(false);
            setSignInShowMessageOutsideForm(false);
            setSignInMessage(
              <>
                Il semble que tu n&apos; aies pas encore créé ton compte. <br /> Ou bien que toutes les conditions
                requises ne soient pas remplies. Inscrit toi dabord !
              </>,
            );
            break;

          default:
            setSignInMessageVariant("notice");
            setSignInLoading(false);
            setSignInShowMessageOutsideForm(false);
            setSignInMessage(`Une erreur inconnue s'est produite : ${error.message}`);
        }
      } else {
        setSignInLoading(false);
        setSignInMessage(`Une erreur s'est produite : ${error.message}`);
      }
    } else {
      setSignInMessage("Un email a été envoyé avec succès ! ✨");
      setSignInShowMessageOutsideForm(true);
      setSignInLoading(false);
      setSignInMessageVariant("positive");
    }
    setSignInLoading(false);
  };

  return {
    status,
    signInLoading,
    signInMessage,
    signInMessageVariant,
    signInShowMessageOutsideForm,
    signInWithOtp,
  };
};

export default useSupabaseSignInWithOtp;
