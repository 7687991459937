import PropTypes from "prop-types";

import { ErrorBoundary } from "react-error-boundary";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { lazy, Suspense, memo, useEffect, useState, useContext, useMemo } from "react";

import SectionScrollable, { ScrollTo } from "react-scroll-id";
import { useHotkeys } from "react-hotkeys-hook";

import Seo from "./Seo";
import Breadcrumb from "./Breadcrumb";
import Loading from "../Loading";

import UserSession from "../../contexts/UserSession";
import UserAccessibility from "../../contexts/UserAccessibility";

import GetLayoutData from "../../config/GetLayoutData";
import { PRIVATE_ROUTES } from "../../constants/privateroutes";
import { PUBLIC_ROUTES } from "../../constants/publicroutes";

import ErrorFallback from "../ErrorFallback";
import PublicFooter from "../PublicFooter";

import { Heading, View, Flex, Text, ActionButton, DialogTrigger, Tooltip, TooltipTrigger } from "@adobe/react-spectrum";

import FilterHeart from "@spectrum-icons/workflow/FilterHeart";
import ShoppingCart from "@spectrum-icons/workflow/ShoppingCart";

const SetlazyOffer = memo(lazy(() => import("../../components/Offer")));

export default function Layout({ children }) {
  const { toggleTheme, toggleMenu, openDyslexic } = useContext(UserAccessibility);
  const nav = useNavigate();

  // React Hotkeys Hook
  useHotkeys("alt+t", () => toggleMenu());
  useHotkeys("alt+r", () => toggleTheme());
  useHotkeys("alt+m", () => nav("../../" + PUBLIC_ROUTES.contact));
  useHotkeys("alt+a", () => ScrollTo({ targetId: "main-content", behavior: "smooth" }));

  const { sessionRole } = useContext(UserSession);
  const [layoutData, setLayoutData] = useState(null);

  const location = useLocation();
  const { pathname } = location;

  const startLink = useMemo(() => {
    return sessionRole === "authenticated" ? `../private/${PRIVATE_ROUTES.accueil}` : PUBLIC_ROUTES.index;
  }, [sessionRole]);

  const key = `SEO_KEY${pathname}&${startLink}`;

  const isPrivateRoute = useMemo(() => matchPath({ path: PRIVATE_ROUTES.private + "/*" }, pathname), [pathname]);

  const isPrivate = matchPath({ path: PRIVATE_ROUTES.private + "/*" }, location.pathname);

  useEffect(() => {
    const data = GetLayoutData(pathname, startLink, isPrivateRoute);
    setLayoutData(data);
  }, [pathname, startLink, isPrivateRoute]);
  return (
    <>
      <SectionScrollable>
        <View
          UNSAFE_className={openDyslexic ? "isOpenDyslexic" : ""}
          backgroundColor="gray-100"
          colorVersion={6}
          padding="size-100"
          data-pagefind-weight="9"
          data-pagefind-meta={layoutData?.description}
          minHeight="100vh"
          paddingBottom="size-1000"
        >
          {layoutData && (
            <>
              {/* METADATA */}
              <Seo
                key={key}
                title={layoutData?.pageTitle}
                description={layoutData?.description}
                name={layoutData?.name}
                type={layoutData?.type}
                keywords={layoutData?.keywords}
                isPrivate={isPrivateRoute}
                pathname={pathname}
              />

              {/* BREADCRUMB */}
              <Breadcrumb breadcrumbItems={layoutData?.breadcrumbItems} />

              {/* ShoppingCart */}
              {isPrivate && (
                <DialogTrigger type="tray">
                  <TooltipTrigger>
                    <View marginTop="size-100" colorVersion={6} backgroundColor="purple-700" borderRadius="regular">
                      <ActionButton width="100%" isQuiet aria-label="Acheter un forfait">
                        <ShoppingCart aria-label="Caddie" />
                        <Text>
                          {" "}
                          <span lang="en">Tea for Two and Two for Tea Just me for you And you for me...</span>
                        </Text>
                      </ActionButton>
                    </View>
                    <Tooltip showIcon="true">Prendre un Forfait</Tooltip>
                  </TooltipTrigger>
                  <Suspense fallback={<Loading />}>
                    <SetlazyOffer />
                  </Suspense>
                </DialogTrigger>
              )}

              {/* TITLE H1 */}

              <Heading
                id="main-content"
                marginTop="size-50"
                marginBottom="size-50"
                level={1}
                data-pagefind-meta={layoutData?.title}
                data-pagefind-weight="10"
              >
                {layoutData?.pageTitle}
              </Heading>
              <Text>{layoutData?.subTitle}</Text>

              {/* ERROR HANDLER */}
              <ErrorBoundary fallbackRender={ErrorFallback}>
                <Flex direction="column" marginTop="size-300" justifyContent="center" alignItems="center">
                  {children}
                </Flex>
              </ErrorBoundary>

              {/* FOOTER */}
              {!isPrivateRoute && <PublicFooter />}
            </>
          )}
        </View>
      </SectionScrollable>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
