import { v4 as uuidv4 } from "uuid";

let circles = [
  {
    id: uuidv4(),
    title: "Autostoper",
    key: "hitchhikers",
    emoji: "🚶",
    text: "Rencontrez de nouveaux amis tout en allant dans la même direction. Que vous soyez une personne qui demande à être prise en stop ou une personne qui le fait, ce cercle est fait pour vous.",

    topic: "Hitchhiker trip",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "Nomades",
    key: "nomads",
    emoji: "🌍",
    text: "Vagabonds des étoiles : Que vous vous trouviez dans un endroit pour un jour, une semaine, un mois ou une année, c'est le cercle des vagabonds et des Nomades qui se sentent chez eux là où ils ont la Vibes.    ",

    topic: "Nomads wanderlust",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "Rando",
    key: "hiking",
    emoji: "🥾",
    text: "Pour les personnes qui aiment les activités de plein air et les randonnées dans la nature. Vous aimeriez trouver des membres qui peuvent vous recommander les meilleures randonnées locales ? Peut-être se joindront-ils à vous pour la randonnée. Ce cercle est destiné à ceux qui aiment être actifs en plein air.",

    topic: "Hiking nature",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "Végé",
    key: "vegetarians",
    emoji: "🥗",
    text: "Pour la santé, pour les animaux ou pour la terre. Rejoignez ce cercle si vous êtes végétalien ou végétarien. ",
    topic: "Vegans Vegetarians",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "Zero déchet",
    key: "zerowaste",
    emoji: "♻️",
    text: "Vous aimez transformer les déchets en trésors ? Ce cercle est pour vous ! Un cercle pour ceux qui trouvent de la valeur cachée dans nos poubelles. Qu'il s'agisse de recycler, de sauter, de sauver de la nourriture, de sauver des meubles, de glaner ou d'autre chose, vous trouverez d'autres personnes comme vous dans ce cercle d'amoureux de la benne à ordures.",

    topic: "sustainable living",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "Éco-habitat",
    key: "ecohabitat",
    emoji: "🏡",
    text: "Vous souhaitez vivre en réduisant l'impact sur notre planète ? Peut-être le faites-vous déjà et souhaitez-vous partager et vous connecter. Pour ceux qui désirent une vie avec moins d'empreinte, ce cercle vous souhaite la bienvenue.",

    topic: "Ecoliving cabin sustainable lifestyle",
    picture: "90",
  },
  {
    id: uuidv4(),
    title: "Cyclistes",
    key: "cyclists",
    emoji: "🚴",
    text: "Des jambes d'acier ou peut-être simplement des ambitions de grand tour ? Les cyclistes se connectent à ce cercle, avant, pendant et après avoir pris la route.",

    topic: "Cyclists biking cycling mountain biking",
    picture: "839",
  },
  {
    id: uuidv4(),
    title: "Table Ouverte",
    key: "opentable",
    emoji: "🍽️",
    text: "A toutes celles et ceux qui aiment les tables ouvertes famille, amis, voisins et les autres ! les autres !!!",

    topic: "Foodsharing meals",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "Artistes",
    key: "artists",
    emoji: "🎨",
    text: "Créateurs et créatifs : vous aimez parler de votre dernier projet ? Vous souhaitez qu'un  membre vous accompagne dans votre démarche créative ? Ce cercle est fait pour vous.",

    topic: "street art graffitis",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "Musiciens",
    key: "musicians",
    emoji: "🎵",
    text: "Vous aimez jouer avec d'autres musiciens ? Ce cercle peut être un bon moyen de les trouver.",

    topic: "Musicians bands singers songwriters",
    picture: "836",
  },
  {
    id: uuidv4(),
    title: "Spiritualités",
    key: "spirituality",
    emoji: "📿",
    text: "Vous êtes-vous déjà demandé s'il y avait quelque chose de plus dans la vie ? Ce cercle est fait pour vous, quelles que soient vos convictions.",

    topic: "Spirituals mindfulness meditation yoga",
    picture: "780",
  },
  {
    id: uuidv4(),
    title: "Cuisine",
    key: "cooking",
    emoji: "🍳",
    text: "Du bon vivant au glouton, tant qu'on le fait soi-même ou ensemble, tout va bien !",

    topic: "Cooking recipes foodies chefs",
    picture: "292",
  },
  {
    id: uuidv4(),
    title: "Yoga",
    key: "yoga",
    emoji: "🧘",
    text: "Pour les adeptes du yoga : rencontrez vos semblables dans ce cercle de yogis et de yoginis.",

    topic: "Yoga meditation wellness mindfulness",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "Outdoor",
    key: "outdoor",
    emoji: "🏕️",
    text: "Pour les personnes qui aiment les activités de plein air et les randonnées dans la nature. Vous aimeriez trouver des personnes qui peuvent vous recommander les meilleures randonnées locales ? Peut-être se joindront-ils à vous pour la randonnée. Ce cercle est destiné à ceux qui aiment être actifs en plein air.",

    topic: "Outdoor sports mountain adventure",
    picture: "375",
  },
  {
    id: uuidv4(),
    title: "DIY",
    key: "diy",
    emoji: "🔧",
    text: "Aux passionnés du bricolage, aux adeptes du chalumeau, et aux bricoleurs du dimanche qu'on peut appeler à n'importe quelle heure ! À ceux qui aiment l'odeur de l'huile de vidange et du bois fraîchement coupé.",

    topic: "Recycling DIY upcycling repurposing",
    picture: "284",
  },
  {
    id: uuidv4(),
    title: "Permaculture",
    key: "permaculture",
    emoji: "🥕",
    text: "Que vous disposiez d'un petit jardin urbain sur votre balcon ou d'un terrain suffisant pour vivre en autarcie, cet endroit est fait pour vous !",

    topic: "Organic bio farmer",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "Photo",
    key: "photographers",
    emoji: "📸",
    text: "Une image vaut mille mots",

    topic: "Photographers photography photojournalism",
    picture: "319",
  },
  {
    id: uuidv4(),
    title: "Pride",
    key: "pride",
    emoji: "🏳️‍🌈",
    text: "Un cercle pour les lesbiennes, gays, bi, trans, queers et leurs alliés.",

    topic: "LGBTQ pride",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "VAN-LIFE",
    key: "vanlife",
    emoji: "🚐",
    text: "Pour les personnes qui prennent la route de façon nomade dans leur fourgon, camping-car, motorhome, bus ou autre ! Si votre van est assez grand, pensez à l'aventure à deux, en famille ?!...",

    topic: "Vanlife",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "Punks",
    key: "punks",
    emoji: "🧷",
    text: "Punk à chien ? Zonard ? marginal errant des années 80 ? C'est parfait le cercle ultime !",
    topic: "Punks punk rock tattoo",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "Naturistes",
    key: "nudists",
    emoji: "X",
    text: "Un cercle pour ceux qui pratiquent, prônent et défendent la nudité personnelle et sociale - que ce soit individuellement, en famille ou à l'extérieur.",

    topic: "nudity",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "Féministes",
    key: "feminists",
    emoji: "✊",
    text: "Un féministe est une personne qui reconnaît l'égalité et la pleine humanité des femmes et des hommes.",

    topic: "Feminists feminism equality",
    picture: "660",
  },
  {
    id: uuidv4(),
    title: "Hippies",
    key: "hippies",
    emoji: "☮️",
    text: "Rejoignez ce cercle si vous vous sentez membre de la famille PEACE AND LOVE. C'est génial de camper dans la nature, de vivre chill, d'apprecier le vivant, la zik... Vivre son aventure",

    topic: "Woodstock peace and love hippie",
    picture: "104",
  },
  {
    id: uuidv4(),
    title: "Hackers",
    key: "hackers",
    emoji: "🏴‍☠️",
    text: "Vous n'avez pas peur des :(){ :|:& }; : - rejoignez les autres hackers de ce cercle pour neirds, geeks. En prime, aidez-nous à signaler les bugs",

    topic: "white hat Hackers programming cybersecurity",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "Familles",
    key: "families",
    emoji: "👨‍👩‍👧‍👦",
    text: "C'est formidable de partager la vie avec des enfants. Si vous êtes une petite ou une grande famille ou que c'est votre envie, rejoignez ce cercle !!! C'est agréable le voyage en famille, la vie de famille, les enfants. LA VIE !!!!",

    topic: "Families parenting kids",
    picture: "815",
  },
  {
    id: uuidv4(),
    title: "Pèlerins",
    key: "pilgrims",
    emoji: "🐚",
    text: "Aux amateurs de mandala, aux pèlerins immobiles, aux pèlerins de Saint-Jacques-de-Compostelle des grandes routes sacrées. Ceux qui vivent le voyage plus intensément que la destination.",

    topic: "Pilgrims pilgrimage spiritual journey",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "Vieux loup de mer ",
    key: "seashepherd",
    emoji: "⚓",
    text: "Pour les marins et les gens de mer. Avis aux profanes ! Ces eaux sont réservées aux marins...d'eaux douces aussi !",

    topic: "Sailors boating maritime",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "Inter ...mittent",
    key: "freelancers",
    emoji: "-/-",
    text: "C'est tout un status d'être intermittent. Un art de vivre à la Française.",

    topic: "Workers freelancers gig economy",
    picture: "117",
  },
  {
    id: uuidv4(),
    title: "Surfeurs",
    key: "surfers",
    emoji: "🏄",
    text: "PLOUF. La vitamine ACE est tout ce dont nous avons besoin. Si vous vous sentez à l'aise dans l'eau avec une planche, c'est l'endroit qu'il vous faut. Les surfeurs de vagues, de kitesurf, de vent et de body-surf trouvent ici d'autres thalassophiles. A bientôt dans l'eau.",

    topic: "Surfers surfing waves beach",
    picture: "845",
  },
  {
    id: uuidv4(),
    title: "Acroyoga",
    key: "acroyoga",
    emoji: "🤸",
    text: "Praticiens de l'acroyoga : vous aimez faire du yoga avec un ou deux partenaires ? Ou peut-être même à l'envers ?",

    topic: "Acroyoga",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "Alpinistes",
    key: "alpinists",
    emoji: "⛰️",
    text: "Pour ceux qui aiment monter dans les montagnes, les parkour, le ski, la snowboard, le paragliding, l'alpinisme, etc. Vous aimeriez trouver des personnes qui peuvent vous recommander les meilleures randonnées locales ? Peut-être se joindront-ils à vous pour la randonnée. Ce cercle est destiné à ceux qui aiment être actifs en plein air.",

    topic: "Alpinistes",
    picture: "",
  },

  {
    id: uuidv4(),
    title: "Ravers",
    key: "ravers",
    emoji: "🎧",
    text: "Vous aimez danser sur des rythmes endiablés dans des lieux souterrains et secrets ? Venez vous joindre à nous et découvrez où se déroule la fête dans le monde entier. Si tu sais, tu sais.",

    topic: "ravers underground",
    picture: "158",
  },
  {
    id: uuidv4(),
    title: "Jongleurs",
    key: "jugglers",
    emoji: "🤹",
    text: "Communauté de jongleurs : apportez vos balles de jonglage, vos quilles, vos épées et vos torches ! Si vous êtes jongleur ou si vous vous intéressez à la jonglerie, cette communauté est faite pour vous.",

    topic: "Jugglers juggling",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "Bikers et mobylettes",
    key: "bikers",
    emoji: "🛵",
    text: "IL n'y a pas que la route 66 il y a aussi vous avec la moto ou la mobilette pour les poêtes.",

    topic: "bikers 66",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "Circus",
    key: "circus",
    emoji: "🎪",
    text: "Vous roulez régulièrement sur une seule roue ? Aimez-vous vous balancer sur un trapèze ? Marcher sur une corde fine vous semble-t-il aussi normal que de marcher sur des surfaces planes ? L'un des arts du cirque vous rend-il incroyablement heureux ? Découvrez où se trouvent les autres passionnés en rejoignant ce cercle.",

    topic: "Circus",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "Slackliners",
    key: "slackliners",
    emoji: "―",
    text: "Rejoindre ce cercle est aussi facile que de tomber d'une corde de 4 cm...",

    topic: "slackline",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "Dance",
    key: "dance",
    emoji: "💃",
    text: "GROOVE! Danser pour s'amuser, danser pour se défouler, danser pour se connecter aux autres... tout ce qui fait bouger vos fesses nous convient. Rejoignez ce cercle et entrez dans la danse. Danse moderne, salsa, danse africaine... tout ce qui vous fait vibrer ! Toute personne intéressée par le mouvement humain artistique, quel qu'il soit !",

    topic: "salsa dance groove",
    picture: "778",
  },
  {
    id: uuidv4(),
    title: "Skateurs",
    key: "skaters",
    emoji: "🛹",
    text: "Les rues sont votre terrain de jeu. Que vous préfériez le skateboard, le longboard ou le cruiser, rejoignez cette communauté roulante pour trouver d'autres passionnés de roues.",

    topic: "Skateboarders skating skateboard",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "RGNR - Santé",
    key: "rgnr",
    emoji: "🥝",
    text: "La santé. Se régénérer. Vivre.",

    topic: "alternative medicine",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "Runners",
    key: "runners",
    emoji: "🏃",
    text: "Partout où vous voyagez, vos chaussures de course vous accompagnent ? Plus il y a de kilomètres, mieux c'est ? Vous voyagez même pour les grandes compétitions ? Que vous soyez un coureur occasionnel, un coureur libre, un marathonien ou un coureur de fond, ce groupe est fait pour vous.",
    topic: "Runners running marathon",
    picture: "770",
  },
  {
    id: uuidv4(),
    title: "Cinéastes",
    key: "filmmakers",
    emoji: "🎥",
    text: "Tenir un appareil photo, être devant, travailler sur l'aspect technique, voyager pour trouver les meilleurs réglages : si tout cela vous semble familier, c'est le cercle qu'il vous faut rejoindre. Trouvez d'autres passionnés, échangez vos expériences, travaillez ensemble sur des projets et, bien sûr, voyagez à travers le monde.",
    topic: "Filmmakers cinema movies",
    picture: "349",
  },
  {
    id: uuidv4(),
    title: "Brasseurs de binouzes",
    key: "brewers",
    emoji: "🍺",
    text: "Plus besoin d'être un moine de nos jours, il suffit d'être un passionné de ce métier. Retrouvez vos collègues brasseurs ici pour échanger des recettes, obtenir des conseils et, bien sûr, savourer ensemble une bière artisanale. Cul sec !",
    topic: "Beer brewers",
    mention: "L'abus d'alcool est dangereux pour la santé, à consommer avec modération",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "Vignerons",
    key: "winemakers",
    emoji: "🍷",
    text: "Le sol, le soleil, le raisin, c'est ici que vous trouverez vos égaux.",
    topic: "Winemakers wine",
    mention: "L'abus d'alcool est dangereux pour la santé, à consommer avec modération",
    picture: "674",
  },
  {
    id: uuidv4(),
    title: "60",
    key: "sixties",
    emoji: "✌️",
    text: "Âmes libres de l'époque psychédélique : Rejoignez la communauté des esprits libres et explorez le monde de la paix, de l'amour et de la musique.",
    nicktitle: "Peace & Love Generation",
    topic: "woodstock",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "70",
    key: "seventies",
    emoji: "🌼",
    text: "Paillettes, lumières, rappel-toi !",
    nicktitle: "Disco Generation",
    topic: "disco",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "80",
    key: "eighties",
    emoji: "📼",
    text: "Generation MTV, synthwave à travers le temps et l'espace.",
    nicktitle: "Generation MTV",
    topic: "Retro-futurism",
    picture: "",
  },
  {
    id: uuidv4(),
    title: "90",
    key: "nineties",
    emoji: "👨‍🎤",
    text: "C'est la fin du minitel et de la grosse TV...NIRVANAAAAAAAA !",
    nicktitle: "Generation X",
    topic: "nineties",
    picture: "",
  },
  {
    id: uuidv4(),

    title: "Millennials",
    key: "millennials",
    emoji: "📱",
    text: "Techos sans frontières.",
    nicktitle: "Generation Tech",
    topic: "millennials",
    picture: null,
  },

  {
    id: uuidv4(),
    title: "Plongeurs",
    key: "divers",
    emoji: "🤿",
    text: "Si vous aimez passer plus de temps sous l'eau que sur terre à explorer des mondes étonnants, ce cercle peut vous convenir.",

    topic: "Scuba divers diving underwater exploration",
    picture: "874",
  },
];

export { circles };
