import { useState } from "react";
import { supabase } from "../supabaseClient";

const useUpdateIsActiveToSupabase = () => {
  const [validationState, setValidationState] = useState("");

  const handleUpdateIsActiveToSupabase = async (bool) => {
    try {
      const { error } = await supabase.auth.updateUser({
        data: {
          pseudo: undefined,
          terms: undefined,
          is_active: bool,
        },
      });
      if (error) {
        setValidationState({
          name: "Erreur lors de la désactivation",
          message:
            "Oops, nous rencontrons une erreur lors de la désactivation. Nous faisons de notre mieux pour résoudre cela rapidement. Si le problème persiste, contacte-nous pour obtenir de l'assistance.",
        });
        return;
      }
    } catch (error) {
      setValidationState({
        name: "Erreur lors de la désactivation",
        message:
          "Une erreur inattendue s'est produite lors de la désactivation de ton compte. Nous nous excusons pour la gêne occasionnée. Contacte-nous pour obtenir de l'assistance.",
      });
      return;
    }
  };
  return { handleUpdateIsActiveToSupabase, validationState };
};

export default useUpdateIsActiveToSupabase;
