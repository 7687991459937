import { useState, useCallback } from "react";
import { TERMS } from "../constants/terms";

const useCheckboxValidation = () => {
  const [selected, setSelected] = useState([]);
  const [areCheckboxesValid, setAreCheckboxesValid] = useState(false);

  const handleCheckboxChange = useCallback((value) => {
    setSelected(value);
    const isValid =
      value.length === 3 && value.includes(TERMS.RGPD) && value.includes(TERMS.cookies) && value.includes(TERMS.CGV);
    setAreCheckboxesValid(isValid);
  }, []);

  return { selected, areCheckboxesValid, handleCheckboxChange };
};

export default useCheckboxValidation;
