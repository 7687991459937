import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import useEmailValidation from "./useEmailValidation";
import usePseudoValidation from "./usePseudoValidation";
import useCheckboxValidation from "./useCheckboxValidation";
import useSecurityEmailVerification from "./useSecurityEmailVerification";
import useSupabaseSignInWithOtp from "./useSupabaseSignInWithOtp";
import { FORMTYPES } from "../constants/formTypes";
import useVibration from "../hooks/useVibration";

const useSignInWithOTP = (initialFormType, captchaToken) => {
  const isSignUp = initialFormType === FORMTYPES.signUP;
  const isSignIn = initialFormType === FORMTYPES.signIn;

  // Initialisation des états
  const [loading, setLoading] = useState("");
  const [message, setMessage] = useState("");
  const [messageVariant, setMessageVariant] = useState("");
  const [showMessageOutsideForm, setShowMessageOutsideForm] = useState(false);

  // Utilisation des hooks de validation
  const { email, isEmailValid, handleEmailChange } = useEmailValidation();
  const { pseudo, isPseudoValid, handlePseudoChange } = usePseudoValidation();
  const { selected, areCheckboxesValid, handleCheckboxChange } = useCheckboxValidation();

  const {
    securityEmailLoading,
    securityEmailMessage,
    securityEmailMessageVariant,
    securityEmailShowMessageOutsideForm,
    securityEmailVerification,
  } = useSecurityEmailVerification();

  const { signInLoading, signInMessage, signInMessageVariant, signInShowMessageOutsideForm, signInWithOtp, status } =
    useSupabaseSignInWithOtp();

  const { vibrate } = useVibration();

  // Effet pour mettre à jour les états en fonction des hooks de connexion
  useEffect(() => {
    // LOADING
    setLoading(securityEmailLoading || signInLoading);

    // MESSAGES
    securityEmailMessage && setMessage(securityEmailMessage);
    signInMessage && setMessage(signInMessage);

    // VARIANTS
    securityEmailMessageVariant && setMessageVariant(securityEmailMessageVariant);
    signInMessageVariant && setMessageVariant(signInMessageVariant);

    setShowMessageOutsideForm(signInShowMessageOutsideForm || securityEmailShowMessageOutsideForm);
  }, [
    signInLoading,
    signInMessage,
    signInMessageVariant,
    signInShowMessageOutsideForm,
    securityEmailLoading,
    securityEmailMessage,
    securityEmailMessageVariant,
    securityEmailShowMessageOutsideForm,
  ]);

  // Fonction pour gérer la connexion avec OTP
  const handleSignInWithOTP = async () => {
    const isEmailVerificationSuccessful = await securityEmailVerification(email);

    if ((isSignUp && (!isEmailValid || !isPseudoValid || !areCheckboxesValid)) || (isSignIn && !isEmailValid)) {
      setLoading(false);
      setMessageVariant("notice");
      setMessage(isSignUp ? "L'ensemble du formulaire est nécessaire." : "Un email est nécessaire.");
      setShowMessageOutsideForm(false);
    } else if (isEmailVerificationSuccessful) {
      await signInWithOtp(
        email,
        pseudo ?? undefined,
        areCheckboxesValid ?? undefined,
        isSignUp ? true : undefined,
        captchaToken,
      );
      vibrate();
    } else {
      setLoading(false);
      setMessageVariant("notice");
      setMessage("Vos informations ne nous permettent de poursuivre en toute sécurité");
      setShowMessageOutsideForm(false);
    }
  };

  // Détermination de la désactivation du bouton
  const isDisabled =
    (isSignUp && (!isEmailValid || !isPseudoValid || !areCheckboxesValid)) || (isSignIn && !isEmailValid);

  return {
    status,
    loading,
    message,
    messageVariant,
    showMessageOutsideForm,
    email,
    isEmailValid,
    handleEmailChange,
    pseudo,
    isPseudoValid,
    handlePseudoChange,
    selected,
    areCheckboxesValid,
    handleCheckboxChange,
    handleSignInWithOTP,
    isDisabled,
  };
};

useSignInWithOTP.propTypes = {
  initialFormType: PropTypes.string.isRequired,
};

export default useSignInWithOTP;
