import { useState, useEffect } from "react";
import { createApi } from "unsplash-js";

const accessKey = import.meta.env.VITE_UNSPLASH_ACCESS_KEY;

const unsplash = createApi({
  accessKey,
});

const useUnsplashPhoto = (photoId) => {
  const [photoUrl, setPhotoUrl] = useState("");
  const [portfolioUrl, setPortfolioUrl] = useState("");
  const [name, setName] = useState("");
  const [altDescription, setAltDescription] = useState("");

  useEffect(() => {
    const fetchPhoto = async () => {
      try {
        const response = await unsplash.photos.get({ photoId });

        if (response.errors) {
          console.error("Error fetching photo:", response.errors);
          return;
        }

        const photo = response.response;

        setPhotoUrl(photo.urls.small_s3);
        setPortfolioUrl(photo.links.html);
        setName(photo.user.name);
        setAltDescription(photo.alt_description);
      } catch (error) {
        console.error("Error fetching photo:", error);
      }
    };

    fetchPhoto();
  }, [photoId]);

  return { photoUrl, portfolioUrl, name, altDescription };
};

export default useUnsplashPhoto;
