import { useContext, useState, useEffect } from "react";
import { Avatar } from "@adobe/react-spectrum";
import PropTypes from "prop-types";
import UserSession from "../../contexts/UserSession";

function UserAvatar({ size, supabaseUrl = "", text, ...props }) {
  const { sessionPseudo } = useContext(UserSession);
  const [avatarUrl, setAvatarUrl] = useState("");

  useEffect(() => {
    const determineAvatarUrl = () => {
      if (supabaseUrl) {
        setAvatarUrl(supabaseUrl);
      } else if (text) {
        setAvatarUrl(
          `https://api.dicebear.com/9.x/pixel-art-neutral/svg?seed=${text}&eyes=variant01,variant03,variant04,variant02,variant06,variant05,variant09&mouth=happy03,happy04,happy05,happy06,happy07,happy08,happy09,happy10,happy11`,
        );
      } else if (sessionPseudo) {
        setAvatarUrl(
          `https://api.dicebear.com/9.x/pixel-art-neutral/svg?seed=${sessionPseudo}&eyes=variant01,variant03,variant04,variant02,variant06,variant05,variant09&mouth=happy03,happy04,happy05,happy06,happy07,happy08,happy09,happy10,happy11`,
        );
      } else {
        setAvatarUrl(`https://api.dicebear.com/9.x/icons/svg?seed=Misty&icon=camera`);
      }
    };

    determineAvatarUrl();
  }, [supabaseUrl, sessionPseudo, text]);

  return <Avatar {...props} size={`avatar-size-${size}`} src={avatarUrl} alt="Ta photo de profil" />;
}

UserAvatar.propTypes = {
  size: PropTypes.number.isRequired,
  supabaseUrl: PropTypes.string,
  text: PropTypes.string,
};

export default UserAvatar;
