import { useState } from "react";

const useSecurityEmailVerification = () => {
  const [securityEmailLoading, setSecurityEmailLoading] = useState(false);
  const [securityEmailMessage, setSecurityEmailMessage] = useState("");
  const [securityEmailMessageVariant, setSecurityEmailMessageVariant] = useState("");
  const [securityEmailShowMessageOutsideForm, setSecurityEmailShowMessageOutsideForm] = useState(false);

  const handleErrorMessage = (errorMessage) => {
    setSecurityEmailLoading(false);
    setSecurityEmailMessageVariant("notice");
    setSecurityEmailShowMessageOutsideForm(false);
    setSecurityEmailMessage(errorMessage);
  };

  const securityEmailVerification = async (email) => {
    const encodedEmail = encodeURIComponent(email);

    // Promesses pour stocker les réponses des deux appels d'API
    const promise1 = fetch(`https://checkmailipqualityscore.bluecornflakes.workers.dev/?email=${encodedEmail}`);

    const promise2 = fetch(`https://maileroo.bluecornflakes.workers.dev/?email=${encodedEmail}`);

    if (!encodedEmail) {
      handleErrorMessage("Le Tramway vérifie ton courriel...");
      return;
    }

    try {
      setSecurityEmailLoading(true);
      // Attendre que toutes les promesses soient résolues

      const [response1, response2] = await Promise.all([promise1, promise2]);

      // Extraire les données JSON des réponses
      const data1 = await response1.json();
      const data2 = await response2.json();

      // Vérifier les résultats des deux appels d'API
      const success1 = data1.success;
      const success2 = data2.success;
      const successMessage = data1.success ? data1.message : data2.message;

      if ((success1 && success2) || (!success1 && success2) || (success1 && !success2)) {
        setSecurityEmailLoading(false);
        setSecurityEmailMessageVariant("positive");
        setSecurityEmailShowMessageOutsideForm(false);
        setSecurityEmailMessage(successMessage);
        return true;
      } else if (!success1 && !success2) {
        handleErrorMessage(successMessage);
        return false;
      }
    } catch (error) {
      handleErrorMessage(
        `Nous rencontrons un problème technique lors de la vérification de ton email. Nous nous en occupons au plus vite ! ERREUR : ${error}`,
      );
      return false;
    }
  };

  return {
    securityEmailLoading,
    securityEmailMessage,
    securityEmailMessageVariant,
    securityEmailShowMessageOutsideForm,
    securityEmailVerification,
  };
};

export default useSecurityEmailVerification;

// import ky from "ky";
// import { useState } from "react";

// const useSecurityEmailVerification = () => {
//   const [securityEmailLoading, setSecurityEmailLoading] = useState(false);
//   const [securityEmailMessage, setSecurityEmailMessage] = useState("");
//   const [securityEmailMessageVariant, setSecurityEmailMessageVariant] = useState("");
//   const [securityEmailShowMessageOutsideForm, setSecurityEmailShowMessageOutsideForm] = useState(false);

//   const handleErrorMessage = (errorMessage) => {
//     setSecurityEmailLoading(false);
//     setSecurityEmailMessageVariant("notice");
//     setSecurityEmailShowMessageOutsideForm(false);
//     setSecurityEmailMessage(errorMessage);
//   };

//   const securityEmailVerification = async (email) => {
//     if (!email) {
//       // handleErrorMessage(
//       //   "Nous rencontrons un problème technique lors de la vérification de ton email. Nous nous en occupons au plus vite ! Ton email est absent.",
//       // );
//       handleErrorMessage("Le Tramway vérifie ton courriel...");
//       return;
//     }

//     try {
//       setSecurityEmailLoading(true);
//       const response = await ky
//         .post(`/api/ipqualityscoreforemail/${encodeURIComponent(email)}`, {
//           headers: {
//             "ipqs-key": import.meta.env.VITE_IPQUALITYSCORE_API_KEY,
//           },
//         })
//         .json();

//       if (response.success && response.valid) {
//         setSecurityEmailLoading(false);
//         setSecurityEmailMessageVariant("positive");
//         setSecurityEmailShowMessageOutsideForm(false);
//         setSecurityEmailMessage(response.message);
//         return true;
//       } else if (!response.success || !response.valid) {
//         handleErrorMessage(response.message);
//         return false;
//       }
//     } catch (error) {
//       handleErrorMessage(
//         `Nous rencontrons un problème technique lors de la vérification de ton email. Nous nous en occupons au plus vite ! ERREUR : ${error}`,
//       );
//     }
//   };
//   return {
//     securityEmailLoading,
//     securityEmailMessage,
//     securityEmailMessageVariant,
//     securityEmailShowMessageOutsideForm,
//     securityEmailVerification,
//   };
// };

// export default useSecurityEmailVerification;
