import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { MENU_NAV } from "../constants/nav";
import { PUBLIC_ROUTES } from "../constants/publicroutes";

import useShareApp from "../hooks/useShareApp";
import PublicSubFooter from "./PublicSubFooter";
import SetDPODialog from "./SetDPODialog";
import SearchTray from "./SearchTray";

import { DialogContainer, ListBox, Divider, Item, Section, Text, Flex, View, Footer } from "@adobe/react-spectrum";

import LinkOut from "@spectrum-icons/workflow/LinkOut";
import ShareAndroid from "@spectrum-icons/workflow/ShareAndroid";
import Magnify from "@spectrum-icons/workflow/Magnify";

export default function PublicFooter() {
  const { handleShare } = useShareApp();

  let [isOpenRGPD, setOpenRGPD] = useState(false);
  let [isOpenSearch, setOpenSearch] = useState(false);

  let tramwayOptions = [
    {
      name: "TIERS-LIEN",
      children: [
        { id: MENU_NAV.manifesto, name: "Manifesto", icon: "", description: "" },
        { id: MENU_NAV.avis, name: "Livre d'Or...", icon: "", description: "" },
        { id: MENU_NAV.accessibilite, name: "Accessibilité", icon: "", description: "" },
        { id: MENU_NAV.mentionsLegales, name: "Mentions légales", icon: "", description: "" },
        { id: MENU_NAV.CGV_CGU, name: "CGV & CGU", icon: "", description: "" },
        { id: "RGPD", name: "RGPD", icon: "", description: "" },
      ],
    },
  ];

  let helpOptions = [
    {
      name: "TRAMWAYers",
      children: [
        { id: MENU_NAV.faq, name: "FAQ's", icon: "", description: "" },
        { id: MENU_NAV.contact, name: "Contact", icon: "", description: "" },
        { id: MENU_NAV.aide, name: "Aide & Sécurité", icon: "", description: "" },
        { id: "search", name: "Rechercher", icon: <Magnify size="XXS" />, description: "" },
      ],
    },
  ];

  let moreHelpOptions = [
    {
      name: "SOCIAL",
      children: [
        {
          id: "X",
          name: "X - Twitter",
          icon: <LinkOut size="XXS" />,
          description: "",
          link: "https://x.com/tramway__RDV",
        },
        {
          id: "KO-FI",
          name: "Blog KO-FI",
          icon: <LinkOut size="XXS" />,
          description: "",
          link: "https://ko-fi.com/bluecornflakes",
        },
        {
          id: "OPQUAST",
          name: "PermaWeb",
          icon: <LinkOut size="XXS" />,
          description: "",
          link: "https://www.opquast.com/",
        },
        { id: "share", name: "Partager", icon: <ShareAndroid size="XXS" />, description: "", link: "" },
        { id: MENU_NAV.sitemap, name: "Plan du site", icon: "", description: "", link: "" },
      ],
    },
  ];

  let [footerId, setFooterId] = useState(new Set());
  const navigate = useNavigate();

  const handleOptions = (id) => {
    setFooterId(id);
    const KEY = id.currentKey;

    switch (KEY) {
      case MENU_NAV.manifesto:
        navigate("../" + PUBLIC_ROUTES.manifesto);
        break;
      case MENU_NAV.avis:
        navigate("../" + PUBLIC_ROUTES.avis);
        break;
      case MENU_NAV.faq:
        navigate("../" + PUBLIC_ROUTES.FAQ);
        break;
      case MENU_NAV.aide:
        navigate("../" + PUBLIC_ROUTES.aide);
        break;
      case MENU_NAV.contact:
        navigate("../" + PUBLIC_ROUTES.contact);
        break;
      case MENU_NAV.accessibilite:
        navigate("../" + PUBLIC_ROUTES.accessibilite);
        break;
      case MENU_NAV.sitemap:
        navigate("../" + PUBLIC_ROUTES.sitemap);
        break;
      case MENU_NAV.mentionsLegales:
        navigate("../" + PUBLIC_ROUTES.mentionsLegales);
        break;
      case MENU_NAV.CGV_CGU:
        navigate("../" + PUBLIC_ROUTES.CGV_CGU);
        break;
      case "RGPD":
        setOpenRGPD(true);
        break;
      case "search":
        setOpenSearch(true);
        break;
      case "share":
        handleShare();
        break;

      default:
        break;
    }
  };

  return (
    <>
      <DialogContainer type="fullscreenTakeover" onDismiss={() => setOpenRGPD(false)}>
        {isOpenRGPD && <SetDPODialog />}
      </DialogContainer>
      <DialogContainer type="modal" isDismissable onDismiss={() => setOpenSearch(false)}>
        {isOpenSearch && <SearchTray />}
      </DialogContainer>

      <View padding="size-250">
        <Divider marginY="size-400" size="S"></Divider>
        <Footer>
          <Flex justifyContent="space-around" wrap direction="row" gap="size-250">
            <PublicSubFooter />

            <Text isHidden>Sélection : {footerId.currentKey}</Text>
            <Flex
              width={{ base: "100%", S: "100%", M: "100%", L: "70%" }}
              justifyContent="space-around"
              direction={{ base: "column", S: "row", M: "row", L: "row" }}
              gap="size-100"
            >
              <View
                colorVersion={6}
                borderRadius="regular"
                backgroundColor=""
                width={{ base: "100%", S: "30%", M: "30%", L: "30%" }}
              >
                <ListBox
                  maxWidth="90%"
                  aria-label="Tramway en savoir plus"
                  items={tramwayOptions}
                  selectionMode="single"
                  onSelectionChange={handleOptions}
                >
                  {(item) => (
                    <Section key={item?.name} items={item?.children} title={item?.name}>
                      {(item) => <Item textValue={item?.name}>{item?.name}</Item>}
                    </Section>
                  )}
                </ListBox>
              </View>
              <Divider orientation={"horizontal"} size="S" />
              <View
                colorVersion={6}
                borderRadius="regular"
                backgroundColor=""
                width={{ base: "100%", S: "30%", M: "30%", L: "30%" }}
              >
                <ListBox
                  width="90%"
                  aria-label="Aide et sécurité"
                  items={helpOptions}
                  selectionMode="single"
                  onSelectionChange={handleOptions}
                >
                  {(item) => (
                    <Section key={item.name} items={item.children} title={item.name}>
                      {(item) => (
                        <Item textValue={item.name}>
                          {item?.icon} <Text>{item.name}</Text>
                        </Item>
                      )}
                    </Section>
                  )}
                </ListBox>
              </View>
              <Divider orientation={"horizontal"} size="S" />
              <View
                colorVersion={6}
                borderRadius="regular"
                backgroundColor=""
                width={{ base: "100%", S: "30%", M: "30%", L: "30%" }}
              >
                <ListBox
                  width="90%"
                  aria-label="Plus d'informations"
                  items={moreHelpOptions}
                  selectionMode="single"
                  onSelectionChange={handleOptions}
                >
                  {(item) => {
                    return (
                      <Section key={item.name} items={item.children} title={item.name}>
                        {(item) => {
                          if (item.link) {
                            return (
                              <Item
                                textValue={item.name}
                                href={item.link}
                                aria-label={`Nouvelle fenêtre - lien externe "${item.name}"`}
                                target="_blank"
                                referrerPolicy="noreferrer nofollow"
                              >
                                {item?.icon} <Text>{item.name}</Text>
                                <Text slot="description"> {item?.description}</Text>
                              </Item>
                            );
                          } else {
                            return (
                              <Item textValue={item.name}>
                                {" "}
                                {item?.icon} <Text>{item.name}</Text>
                              </Item>
                            );
                          }
                        }}
                      </Section>
                    );
                  }}
                </ListBox>
              </View>
            </Flex>
          </Flex>
        </Footer>
      </View>
    </>
  );
}
