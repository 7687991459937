import { useNavigate } from "react-router-dom";
import { PUBLIC_ROUTES } from "../../../constants/publicroutes";
import { Tooltip, Text, TooltipTrigger, Flex, View, ActionButton } from "@adobe/react-spectrum";
import Heart from "@spectrum-icons/workflow/Heart";
import Gift from "@spectrum-icons/workflow/Gift";

function CTAWithReassurance() {
  const nav = useNavigate();
  return (
    <>
      <View
        marginTop="size-250"
        marginBottom="size-100"
        UNSAFE_style={{ backgroundColor: "DarkGoldenRod" }}
        borderRadius="regular"
      >
        <TooltipTrigger>
          <ActionButton
            onPress={() => nav("../" + PUBLIC_ROUTES.creerCompte)}
            width="size-3000"
            aria-label="Prendre un Ticket"
            staticColor="black"
            height="size-600"
            isQuiet
          >
            <Heart />
            <Text>Prendre un Ticket</Text>
          </ActionButton>

          <Tooltip>Aller à la page d'inscription</Tooltip>
        </TooltipTrigger>
      </View>

      <Flex gap="size-100" direction="row" alignItems="center" justifyContent="center">
        <Gift aria-label="cadeau" size="S" color="" />
        <Text
          marginTop="size-0"
          UNSAFE_style={{
            color: "#788128",
            fontSize: "17.5px",
          }}
        >
          2€ = 2 mois
        </Text>{" "}
        <Text
          UNSAFE_style={{
            fontSize: "17.5px",
          }}
        >
          {" "}
          pour les 1000 premiers utilisateurs
        </Text>
      </Flex>
    </>
  );
}

export default CTAWithReassurance;
